import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {store} from '../../app/store';
import {OrderInput} from '../order_input/orderInput';
import {LedgerEvent} from '../SvgGraph/ActorLedger';
import {ActorNameType, DemoScenario} from "../SvgGraph/DemoActor";
import {AtomicNetDocument, DocumentType, getScenarioDocuments} from "../SvgGraph/AtomicNetDocument";
import {InitialScenario} from "../form_components/ScenarioDropdown";
import {generateLedgerEvents} from "./ledgerEvents";

export type EventType =
    "EMPTY" |
    "order sent" | "order received" |
    "CBAR sent" | "CBAR received" | "TBAR sent" | "TBAR received" |
    "CECR sent" | "CECR received" |
    "TBA sent" | "TBA received" | "TECR sent" | "TECR received" | "TEC sent" | "TEC received" |
    "CBA sent" | "CBA received" | "CEC sent" | "CEC received" |
    "OR sent" | "OR received" |
    "OB sent" | "OB received" | "OEC memorialized" |
    "OEC received CB" | "OEC received PAL" | "OEC received C1" | "OEC received C2" | "OEC received OMS" |
    "TOLP1 sent" | "TOLP2 sent" | "COLP1 sent" | "COLP2 sent" | "TOLP1 received" | "TOLP2 received" | "COLP1 received" | "COLP2 received" |
    "order completed";

export const assetToCommonLedgerMap = new Map<string, string>([
    ["PLTR", "Clear Token"],
    ["POLY", "Carta"],
    ["QQQ", "Central Depository"],
    ["THEX", "Central Depository"],
    ["ETF", "DTCC"],
    ["QQda", "MSCO"],
    ["SPV1", "Virt"],
    ["TYAda", "GSCO"],
]);

export interface AuditState {
    auditEntries: Array<AuditEntry>,
    auditStep: AuditEntry,
    memorializedDocuments: Array<object>,
    orderTicket: any,
    totalBQuantity: number,
    sender: string,
    receiver: string,
    displayNameMap: any,
    ledgerState: LedgerEvent[],
    idxForScrolling: number,
    scenario: DemoScenario,
}

interface IAuditEntry {
    src: ActorNameType; // e.g. oms1, custodian1, etc.
    event: EventType;
    message: string; // e.g. Order received by the Order Management System"
    timestamp: number; // e.g. 383858585588
    description: string; // e.g. User has specified all order information...
    delayAmount: number;
}

export class AuditEntry implements IAuditEntry {
    src: ActorNameType;
    dst: ActorNameType
    event: EventType;
    document: DocumentType;
    message: string;
    timestamp: number;
    description: string;
    selected: string;
    delayAmount: number;
    index: number;
}

export const initialAuditStep : AuditEntry = {
    message: "No Entries",
    src: "ia1",
    dst: "oms1",
    document: "o1",
    event: "EMPTY",
    timestamp: Date.now(),
    description: "Order has not been submitted yet.",
    selected: "",
    delayAmount: 0,
    index: 0,
};

const initialState: AuditState = {
    auditEntries: [initialAuditStep],
    memorializedDocuments: [],
    auditStep: initialAuditStep,
    orderTicket: {
        purchasingEntity: ["ia1"],
        custodian: ["custodian"],
        primaryAssetLedger: ["PAL"],
        exchange: ["exchange"],
        asset: ["asset"],
        fiatCurrency: "USD",
        commonBank: ["commonBank"],
        orderSide: "buy",
    },
    totalBQuantity: 0,
    sender: "",
    receiver: "",
    displayNameMap: {},
    ledgerState: [],
    idxForScrolling: 0,
    scenario: InitialScenario,
}

// For individual documents, the log index where they first appear
export let DocumentOrdering = new Map<DocumentType, number>([ ]);

const createLogsWithDelay = (auditEntries: Array<AuditEntry>) => {
    let delaySum = 0;

    for (let i = 1; i <= auditEntries.length + 1; i++) {
        // Take the list of entries that have occurred in our *so far* time and reverse them for proper audit log order
        const entrySlice = auditEntries.slice(0,i).reverse();
        const entryDelay = entrySlice[0].delayAmount * 5;
        const totalDelay = entryDelay + (delaySum);

        delaySum = totalDelay;
        setTimeout(function() {
            entrySlice[0].timestamp = Date.now();
            store.dispatch(auditSlice.actions.setLogSlice(entrySlice))
            auditSlice.actions.setLogSlice(entrySlice);
        },((totalDelay))); //delay is in milliseconds
    }
}

function generateLogMessage({verb, docInfo, values}: {verb: string, docInfo: AtomicNetDocument, values: any}) {
    let docName = docInfo.name.toString().replace(/\s\d$/, "");
    let src = docInfo.srcActor;
    let dst = docInfo.dstActor;

    let srcName = values[src] || src;
    let dstName = values[dst] || dst;
    let an = docName.match(/^Order/) ? "an" : "a";
    console.log("Generating text");
    if (verb === "sent") {
        return `${srcName} ${verb} ${an} ${docName} to ${dstName}`;
    } else {
        return `${dstName} ${verb} ${an} ${docName} from ${srcName}`;
    }
}

// Gross global, but using this to avoid passing scenario all the way down to generateLogMessage.
let auditSliceScenario: DemoScenario = "custodian";

// Create and return a new audit log entry.
function addLogEntry({auditLog, event, desc, delay, document, values}: {
    auditLog: AuditEntry[], event: EventType, desc: string, delay?: number, document: DocumentType, values: any
} ) {
    const docInfo = getScenarioDocuments(auditSliceScenario)[document];

    if (docInfo !== undefined) {
        let entry = new AuditEntry();
        const stripped = desc.replace(/^\n+/, ""); // Strip leading empty lines
        const htmlDesc = stripped.replace(/\n/g, "<br/>");
        const logDelay = delay === undefined ? 100 : delay;
        const matches = event.match(/sent|received|memorialized|completed/)
        const verb = matches === null ? 'NO VERB': matches[0] === "completed" ? "received": matches[0];

        entry.src = docInfo.srcActor;
        entry.dst = docInfo.dstActor;
        entry.message = generateLogMessage({docInfo: docInfo, verb: verb, values: values});
        entry.event = event;
        entry.description = "<p>" + htmlDesc + "</p>";
        entry.delayAmount = logDelay;
        entry.document = document;
        entry.index = auditLog.length;

        auditLog.push(entry);
    }
}

// Generate all log entries for a given scenario and orderInput.
//
// There is a given set of base logs.  Each log line is associated with a particular document.
// If that document is included in the scenario, the log line will be included, otherwise it will not.
//
// This routine also sets the DocumentOrdering map, which orders all the documents
const getLogEntries = (orderInput: OrderInput, scenario: DemoScenario) => {
    auditSliceScenario = scenario;

    if (orderInput.custodian === undefined) {
        orderInput.custodian = 'Custodian';
    }
    if (orderInput.exchange === undefined) {
        orderInput.exchange = 'Exchange';
    }
    const values = {
        exchange: orderInput.exchange[0],
        custodian1: orderInput.custodian[0],

        common_ledger1: orderInput.commonBank[0],
        common_ledger2: orderInput.primaryAssetLedger,

        ia1: "Investment Advisor",
        oms1: "OMS",
        oms2: "Counterparty OMS",
        abc: "the IPS",
    };

    let logs: AuditEntry[] = [];
    addLogEntry({
        auditLog: logs,
        event: "order sent",
        document: "o1",
        desc: `
The investment advisor initiated the purchase or sale by specifying the relevant assets, custodians, amounts,
and prices to the OMS.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "order received",
        document: "o1",
        desc: "The OMS received a valid order from the investment advisor.",
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "OR sent",
        document: "or",
        desc: `The Quote Request is sent out to perspective liquidity providers.`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OR received",
        document: "or",
        desc: `The Quote Request is received by a liquidity provider.`,
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "CBAR sent",
        document: "bar1",
        desc: `
Orders require both a Custodian Beneficiary Authorization from the beneficiary custodian and an Custodian Escrow
Confirmation from the escrow custodian. In preparation for submitting the order, the OMS sent a Custodian Beneficiary
Authorization Request to the beneficiary's custodian to obtain the needed Custodian Beneficiary Authorization.

The signed Custodian Beneficiary Authorization is the custodian's commitment that the beneficiary can receive the
specified asset from any corresponding party that has an Escrow Authorization from that same custodian.

The signed Custodian Escrow Authorization is the escrow custodian's commitment that the specified asset has been
segregated, escrowed, and is clear to trade. The asset is authorized for transfer to any corresponding party with a
Custodian Beneficiary Authorization from that same custodian until the escrow expiration time.
`,
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "CECR sent",
        document: "ecr1",
        desc: `
Orders require both a Custodian Beneficiary Authorization from the beneficiary custodian and an Custodian Escrow
Confirmation from the escrow custodian. In preparation for submitting the order, the OMS sent a Custodian Escrow
Confirmation Request to the beneficiary's custodian to obtain the needed Custodian Escrow Confirmation.

The signed Custodian Beneficiary Authorization is the custodian's commitment that the beneficiary can receive the
specified asset from any corresponding party that has an Escrow Authorization from that same custodian.

The signed Custodian Escrow Authorization is the escrow custodian's commitment that the specified asset has been
segregated, escrowed, and is clear to trade. The asset is authorized for transfer to any corresponding party with a
Custodian Beneficiary Authorization from that same custodian until the escrow expiration time.
`,
        values: values,
    });


    addLogEntry({
        auditLog: logs,
        event: "CBAR received",
        document: "bar1",
        desc: "Custodian received the Custodian Beneficiary Authorization Request from the OMS",
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "CECR received",
        document: "ecr1",
        desc: "Custodian received the Custodian Escrow Confirmation Request from the OMS",
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "TBAR sent",
        document: "tbar1",
        desc: `
Custodians require both a Transfer Beneficiary Authorization from the beneficiary ledger manager and an Transfer Escrow
Authorization from the escrow ledger manager. In preparation for submitting the order, the Custodian sent a Transfer 
Beneficiary Authorization Request to the beneficiary's ledger manager to obtain the needed Transfer Beneficiary
Authorization.

The signed Transfer Beneficiary Authorization is the leger manager's commitment that the beneficiary can receive the
specified asset from any corresponding party that has a Transfer Escrow Authorization from that same ledger manager.

The signed Transfer Escrow Authorization is the escrow ledger manager's commitment that the specified asset has been
segregated, escrowed, and is clear to trade. The asset is authorized for transfer to any corresponding party with a
Transfer Beneficiary Authorization from that same ledger manager until the escrow expiration time.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TBAR received",
        document: "tbar1",
        desc: "Primary Ledger Manager received the Transfer Beneficiary Authorization Request from the OMS",
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TBA sent",
        document: "tba1",
        desc: `
Custodians require both a Transfer Beneficiary Authorization from the beneficiary ledger manager and an Transfer Escrow
Confirmation from the escrow ledger manager.

After receiving a Transfer Beneficiary Authorization Request to the beneficiary's ledger manager, the ledger manager
confirmed the ability of the beneficiary to receive the assets in the order and sent a Transfer Beneficiary
Authorization to the custodian in response.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TBA received",
        document: "tba1",
        desc: `
The custodian received a Transfer Escrow Beneficiary Authorization from the Primary Asset Ledger.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TECR sent",
        document: "tecr1",
        desc: `
Custodians require both a Transfer Beneficiary Authorization from the beneficiary ledger manager and an Transfer Escrow
Confirmation from the escrow ledger manager.

After receiving a Transfer Escrow Confirmation Request to the beneficiary's ledger manager, the ledger manager
confirmed the ability of the beneficiary to receive the assets in the order and sent a Transfer Escrow 
Confirmation to the custodian in response.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TECR received",
        document: "tecr1",
        desc: `
Common Bank received a Transfer Escrow Confirmation Request from custodian.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TEC sent",
        document: "tec1",
        desc: `
Custodians require both a Transfer Escrow Confirmation from the beneficiary ledger manager and an Transfer Escrow
Confirmation from the escrow ledger manager.

After receiving a Transfer Beneficiary Escrow Confirmation to the beneficiary's ledger manager, the ledger manager
confirmed the ability of the beneficiary to receive the assets in the order and sent a Transfer Escrow
Confirmation to the custodian in response.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TEC received",
        document: "tec1",
        desc: `
Custodian received a Transfer Escrow Confirmation Request from ledger manager.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "CBA sent",
        document: "ba1",
        desc: `
The beneficiary's custodian, at the request of the OMS, determined that the client was authorized to receive the
specified asset.
            
The custodian then signed and sent a Beneficiary Authorization to the OMS, which certified that the beneficiary's
account is authorized to receive assets from authorized escrows. The custodian does all of this on behalf of its
client, the investment advisor.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "CBA received",
        document: "ba1",
        desc: `
OMS received a Custodian Beneficiary Authorization from custodian.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "CEC sent",
        document: "ec1",
        desc: `
The escrow custodian responded to the Custodian Escrow Confirmation Request by sending a signed Escrow Authorization
to the OMS.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "CEC received",
        document: "ec1",
        desc: `
The OMS received a Custodian Escrow Confirmation from the custodian.
`,
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "OB sent",
        document: "ob1",
        desc: `
The OMS received both a Beneficiary Authorization from the beneficiary's custodian, and an Escrow Authorization from
the escrow custodian. The OMS then sent an order bundle to the specified exchange to match the order.

The OMS bundled both the signed Custodian Beneficiary Authorization and signed Custodian Escrow Authorization along
with order details into a signed Order Bundle which it sent to an exchange.`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OB received",
        document: "ob1",
        desc: `
The exchange received the Order Bundle from the OMS.
            
The Order Bundle contains both the Custodian Beneficiary Authorization signed by the beneficiary's custodian, and the
Custodian Escrow Authorization signed by the escrow custodian. The Order Bundle represents one half of a trade and
requires a complementary matching order to be completed.

The exchange must now match a crossing Order Bundle to execute the order.`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC memorialized",
        document: "oe_to_abc",
        desc: `
The exchange matched two Order Bundle documents and then created, signed, and memorialized an Order Execution Confirmation on the IPS.

The Order Execution Confirmation references both crossing orders, both Escrow Authorization, and both Beneficiary
Authorizations.

The Order Execution Confirmation was memorialized to the IPS so that it could indisputably be distributed to by all
involved parties.

The Order Execution Confirmation is memorialized on the IPS as a cryptographically provable way to reliably distribute
the Order Execution Confirmation so that the custodians may complete the transfer and avoid legal disputes.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC received CB",
        document: "oe_to_l2",
        desc: `
The common bank received a memorialized Order Execution Confirmation that included its own Escrow Authorization and its
own Beneficiary Authorization.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC received PAL",
        document: "oe_to_l1",
        desc: `
The primary asset ledger received a memorialized Order Execution Confirmation that included its own Escrow
Authorization and its own Beneficiary Authorization.
`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC received C1",
        document: "oe_to_c1",
        desc: `
The custodian received a memorialized Order Execution Confirmation that included its own Escrow Authorization and its
own Beneficiary Authorization.
`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC received C2",
        document: "oe_to_c2",
        desc: `
The custodian received a memorialized Order Execution Confirmation that included its own Escrow Authorization and its
own Beneficiary Authorization.
`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "OEC received OMS",
        document: "oe_to_oms1",
        desc: "The OMS received a memorialized Order Execution Confirmation.",
        delay: 1,
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "TOLP1 sent",
        document: "tolp1_to_abc",
        desc: `
The Order Execution Confirmation and its own self signed Transfer Escrow Confirmation and Transfer Beneficiary
Authorization obligate the custodian to release the escrowed assets to the opposing beneficiary's account. 
 
To avoid legal disputes the custodian saved cryptographic proof of its obligation to release the escrow to the
beneficiary as specified in the Order Execution Confirmation. 

The proof becomes relevant in the rare event where another party to the transaction failed to fulfill its obligation
to complete its leg of the transaction. The proof allows this custodian to prove to its client that it performed
as obligated. 
 
After saving the proof, the escrow custodian released the escrowed asset to the opposing beneficiary's account. 
 
Finally, the custodian created, signed, and memorialized an Transfer Order Leg Processed Confirmation on the IPS.
`,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TOLP2 sent",
        document: "tolp2_to_abc",
        desc: `
The Order Execution Confirmation and its own self signed Transfer Escrow Confirmation and Transfer Beneficiary
Authorization obligate the custodian to release the escrowed assets to the opposing beneficiary's account. 
 
To avoid legal disputes the custodian saved cryptographic proof of its obligation to release the escrow to the
beneficiary as specified in the Order Execution Confirmation. 

The proof becomes relevant in the rare event where another party to the transaction failed to fulfill its obligation
to complete its leg of the transaction. The proof allows this custodian to prove to its client that it performed
as obligated. 
 
After saving the proof, the escrow custodian released the escrowed asset to the opposing beneficiary's account. 
 
Finally, the custodian created, signed, and memorialized an Transfer Order Leg Processed Confirmation on the IPS.
`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "COLP1 sent",
        document: "olp1_to_abc",
        desc: `
The Order Execution Confirmation and its own self signed Custodian Escrow Confirmation and Custodian Beneficiary
Authorization obligate the custodian to debit and credit the investment advisor's balances to reflect the transaction.
 
To avoid legal disputes the custodian saved cryptographic proof of its obligation to release the escrow to the
beneficiary as specified in the Order Execution Confirmation. 

The proof becomes relevant in the rare event where another party to the transaction failed to fulfill its obligation
to complete its leg of the transaction. The proof allows this custodian to prove to its client that it performed
as obligated. 
`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "COLP2 sent",
        document: "olp2_to_abc",
        desc: `
The Order Execution Confirmation and its own self signed Custodian Escrow Confirmation and Custodian Beneficiary
Authorization obligate the custodian to debit and credit the investment advisor's balances to reflect the transaction.
 
To avoid legal disputes the custodian saved cryptographic proof of its obligation to release the escrow to the
beneficiary as specified in the Order Execution Confirmation. 

The proof becomes relevant in the rare event where another party to the transaction failed to fulfill its obligation
to complete its leg of the transaction. The proof allows this custodian to prove to its client that it performed
as obligated. 
`,
        delay: 1,
        values: values,
    });

    addLogEntry({
        auditLog: logs,
        event: "TOLP1 received",
        document: "tolp1_to_oms1",
        desc: `OMS received a Transfer Order Leg Processed document from a custodian via the IPS.`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "TOLP2 received",
        document: "tolp2_to_oms1",
        desc: `OMS received a Transfer Order Leg Processed document from a custodian via the IPS.`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "COLP1 received",
        document: "olp1_to_oms1",
        desc: `OMS received a Custodian Order Leg Processed document from a custodian via the IPS.`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "COLP2 received",
        document: "olp2_to_oms1",
        desc: `OMS received a Custodian Order Leg Processed document from a custodian via the IPS.`,
        delay: 1,
        values: values,
    });
    addLogEntry({
        auditLog: logs,
        event: "order completed",
        document: "tc1",
        desc: `
The OMS received all Order Leg processed Docs from all ledger managers involved in the order. The OMS then reported to
the investment advisor that the transaction was completed. 
 
The Transaction Completed document contains all the elements necessary to prove that all parties certified that
they completed the actions required. This includes all the prior signed documents.
`,
        values: values,
    });

    // Set the index for each log entry, and keep track of where each document first appears in the log by index.
    // This can be used to determine if a document was created before or after a selected log line.
    DocumentOrdering.clear();
    logs.forEach((logEntry, index) => {
        if (DocumentOrdering.get(logEntry.document) === undefined) {
            DocumentOrdering.set(logEntry.document, index);
        }

        /// Hack because there is no oe_to_oms1 in this scenario
        if (scenario === "no_exchange") {
            if (logEntry.document === "or") {
                DocumentOrdering.set("or", index + 1);
            }
            if (logEntry.document === "oe_to_abc") {
                DocumentOrdering.set("oe_to_oms2", index + 1);
            }
        }
    });

    return logs;
}

// Set the default names for the actors on the graph.
function setDefaultDisplayNames(state: AuditState) {
    let scenario = state.scenario;
    state.displayNameMap = {
      "ia1": state.orderTicket.purchasingEntity[0],
      "exchange": state.orderTicket.exchange[0],
      "oms1": "OMS",
      "custodian1": state.orderTicket.custodian[0],
      "common_ledger1": state.orderTicket.commonBank[0],
      "abc": "Irrefutable Publication Service "
    }

    if (scenario === "no_exchange") {
        state.displayNameMap.custodian2 = assetToCommonLedgerMap.get(state.orderTicket.asset[0]);
    } else {
        state.displayNameMap.common_ledger2 = assetToCommonLedgerMap.get(state.orderTicket.asset[0]);
    }
}

export const auditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {
        onScenarioSelect: (state, action: PayloadAction<DemoScenario>) => {
            state.scenario = action.payload;
            state.auditEntries = [initialAuditStep];
            state.auditStep = initialAuditStep;
            state.sender = "";
        },
        onOrderSubmission: (state, action: PayloadAction<OrderInput>) => {
            // This is where we populate, with the payload

            const primaryAssetLedger = assetToCommonLedgerMap.get(action.payload.asset[0]) || "PAL";

            action.payload.primaryAssetLedger = primaryAssetLedger;
            state.orderTicket.primaryAssetLedger = primaryAssetLedger;

            let theEntries = getLogEntries(action.payload, state.scenario);
            state.orderTicket = action.payload;
            setDefaultDisplayNames(state);

            createLogsWithDelay(theEntries);
            state.totalBQuantity = action.payload.quantity * 3;
        },
        setLogSlice: (state, action: PayloadAction<Array<AuditEntry>>) => {
            state.auditEntries = action.payload;
            // This is where set the ledgerState to the appropriate amount of filled in
            state.auditEntries.forEach( (e : any, idx : any) => {
                if(idx === 0){
                    state.auditStep = e;
                    e.selected = "selectedLog";
                } else {
                    e.selected = "";
                }
            })

            let events = generateLedgerEvents(state.auditEntries[0].event, state.orderTicket.orderSide, state.orderTicket.quantity, state.auditEntries, state.scenario);
            state.ledgerState = events;

            if (state.auditStep.dst) {
                let sender = state.auditStep.src;
                if (sender) {
                    state.sender = sender!;
                } else {
                    state.sender = state.auditStep.src;
                }
                let maybeReceiver = state.auditStep.dst;

                if (maybeReceiver) {
                    state.receiver = maybeReceiver;
                } else {
                    state.receiver = state.auditStep.dst;
                }
            }
        },
        stepLogSlice: (state, action: PayloadAction<string>) => {
            let thatString = action.payload;
            const positionInLog = state.auditEntries.findIndex(element => element === state.auditStep);
            state.idxForScrolling = positionInLog;

            if (positionInLog === 0 && thatString === "FORWARDS") {
                return;
            }
            if (positionInLog === state.auditEntries.length - 1 && thatString === "BACKWARDS") {
                return;
            }
            if (thatString === "BACKWARDS" && positionInLog < state.auditEntries.length) {
                state.auditStep = state.auditEntries[positionInLog + 1]; // backwards
            } else {
                if (positionInLog < state.auditEntries.length)
                    state.auditStep = state.auditEntries[positionInLog - 1]; // forwards
            }

            if (state.auditStep.dst) {
                let sender = state.auditStep.src;
                    if (sender) {
                        state.sender = sender!;
                    } else {
                        state.sender = state.auditStep.src;
                    }
                let maybeReceiver = state.auditStep.dst;

                if (maybeReceiver) {
                    state.receiver = maybeReceiver;
                } else {
                    state.receiver = state.auditStep.dst;
                }
            }

            let newEntries : Array<AuditEntry> = [];
            state.auditEntries.forEach((e : any) => {
                if (e.event === state.auditStep.event) {
                    e.selected = "selectedLog";
                } else {
                    e.selected = "";
                }
                newEntries.push(e);
            })
            state.auditEntries = newEntries;
        },
        onStepSelection: (state, action: PayloadAction<AuditEntry>) => {
            state.auditStep = action.payload
            state.idxForScrolling = -1;

            setDefaultDisplayNames(state);

            let sender = state.auditStep.src;

            if (sender) {
                state.sender = sender!;
            } else {
                state.sender = state.auditStep.src;
            }
            let maybeReceiver = state.auditStep.dst;
            if (maybeReceiver) {
                state.receiver = maybeReceiver;
            } else {
                state.receiver = state.auditStep.dst;
            }

            let newEntries: Array<AuditEntry> = [];
            state.auditEntries.forEach((e: any) => {
                if (e.event === action.payload.event) {
                    e.selected = "selectedLog";
                } else {
                    e.selected = "";
                }
                newEntries.push(e);
            })

            let events = generateLedgerEvents(state.auditStep.event, state.orderTicket.orderSide, state.orderTicket.quantity, state.auditEntries, state.scenario);
            state.ledgerState = events;
            state.auditEntries = newEntries
        }
    }
})

export const { onOrderSubmission, onStepSelection, stepLogSlice, onScenarioSelect } = auditSlice.actions
export default auditSlice.reducer